<script>
export default {
  metaInfo() {
    return {
      title: 'Copyright - Europe PMC',
    }
  },
}
</script>
<template>
  <div id="copyright-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Copyright</h1>
        <p>
          This page has information about general copyright restrictions that
          apply to the material that is available through the Europe PMC site.
          There are also links to some
          <a href="#links-to-publisher-specific-copyright-information"
            >Publisher Specific Copyright Information</a
          >.
        </p>
        <p>
          All of the material available through the Europe PMC site is provided
          by the respective publishers or authors. Almost all of it is protected
          by U.K. and/or foreign copyright laws, even though Europe PMC provides
          free access to it (See
          <a href="#public-domain-materials">Public Domain Material</a> below,
          for exceptions).
        </p>
        <p>
          The respective copyright holders retain rights for reproduction,
          redistribution and reuse. Users of Europe PMC are directly and solely
          responsible for compliance with copyright restrictions and are
          expected to adhere to the terms and conditions defined by the
          copyright holder. Transmission, reproduction, or reuse of protected
          material, beyond that allowed by the fair use principles of the
          copyright laws, requires the written permission of the copyright
          owners.
        </p>

        <h2>Copyright Statements in Europe PMC</h2>
        <p>
          Articles and other material in Europe PMC usually include an explicit
          copyright statement. In the absence of a copyright statement, users
          should assume that standard copyright protection applies, unless the
          article contains an explicit statement to the contrary. In case of
          doubt, contact the journal publisher to verify the copyright status of
          an article.
        </p>

        <h2>Restrictions on Systematic Downloading of Articles</h2>
        <p>
          Crawlers and other automated processes may NOT be used to
          systematically retrieve batches of articles from the Europe PMC web
          site. Bulk downloading of articles from the main Europe PMC site, in
          any way, is prohibited because of copyright restrictions.
        </p>
        <p>
          Europe PMC has implemented <a href="/OaiService">OAI</a>,
          <a href="/RestfulWebService">RESTful</a>,
          <a href="/SoapWebServices">SOAP</a>, and
          <a href="/downloads">bulk download</a>
          services that may be used for automated retrieval and downloading of a
          subset of articles from the Europe PMC archive. These are the only
          services that may be used for automated downloading of articles in
          Europe PMC. See the
          <a href="/downloads/openaccess">open access subset</a> for information
          about the articles included. Do not use any other automated processes
          for bulk downloading, even if you are only retrieving articles from
          the Europe PMC open access subset.
        </p>
        <p>
          Articles that are available through the
          <a href="/OaiService">OAI service</a>, the
          <a href="/RestfulWebService">RESTful</a> and
          <a href="/SoapWebServices">SOAP</a> web services, and through
          <a href="/downloads">bulk download</a> are still protected by
          copyright, but are distributed under a Creative Commons or similar
          license that generally allows more liberal use than a traditional
          copyrighted work. Please refer to the license statement in each
          article for specific terms of use. The license terms are not identical
          for all the articles.
        </p>
        <p>
          Please also note that when an open access fee is levied, some funders
          require that publishers license this content with a CC-BY license,
          which allows these articles to be freely copied, distributed,
          displayed and modified into derivative works by any user. These
          funders include:
        </p>
        <ul>
          <li>Versus Arthritis</li>
          <li>Austrian Science Fund</li>
          <li>British Heart Foundation</li>
          <li>Cancer Research UK</li>
          <li>Medical Research Council</li>
          <li>
            National Centre for the Replacement, Refinement & Reduction of
            Animals in Research
          </li>
          <li>National Institute for Health Research</li>
          <li>Wellcome</li>
          <li>Worldwide Cancer Research</li>
        </ul>
        <p>
          These articles are also made available through the
          <a href="/RestfulWebService">RESTful</a> and
          <a href="/SoapWebServices">SOAP</a> web services and
          <a href="/OaiService">OAI service</a>.
        </p>

        <h2 id="public-domain-materials">Public Domain Material</h2>
        <p>
          The following Europe PMC journals are U.S. Government publications:
        </p>
        <ul>
          <li>
            <a href="/issues?0=/journals/351"
              >Addiction Science & Clinical Practice (vol. 1 through vol. 6)</a
            >
          </li>
          <li>
            <a href="/issues?0=/journals/647">Emerging Infectious Diseases</a>
          </li>
          <li>
            <a href="/issues?0=/journals/657"
              >Environmental Health Perspectives</a
            >
          </li>
          <li>
            <a href="/issues?0=/journals/2790">Journal of Research of NIST</a>
          </li>
          <li>
            <a href="/issues?0=/journals/1229">Preventing Chronic Disease</a>
          </li>
        </ul>
        <p>
          Articles published in these journals are in the public domain and may
          be used and reproduced without special permission. However, anyone
          using the material is requested to properly cite and acknowledge the
          source. Please note these journals may still contain photographs or
          illustrations copyrighted by other commercial organizations or
          individuals that may not be used without obtaining prior approval from
          the holder of the copyright.
        </p>

        <h2 id="links-to-publisher-specific-copyright-information">
          Links to Publisher-Specific Copyright Information
        </h2>
        <p>
          Use these links to different publishers' sites for additional
          copyright terms that apply to their respective content in Europe PMC.
        </p>
        <ul>
          <li>
            <a
              href="//journals.asm.org/content/copyright-and-license-information"
              >American Society for Microbiology</a
            >
          </li>
          <li>
            <a href="//www.lifescied.org/info-for-authors"
              >CBE - Life Sciences Education (American Society for Cell
              Biology)</a
            >
          </li>
          <li>
            <a href="//www.molbiolcell.org/info-for-authors"
              >Molecular Biology of the Cell (American Society for Cell
              Biology)</a
            >
          </li>
          <li>
            <a href="//www.pnas.org/page/about/rights-permissions"
              >National Academy of Sciences</a
            >
          </li>
          <li>
            <a href="//academic.oup.com/journals/pages/about_us/legal"
              >Nucleic Acids Research (Open Access content)</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#copyright-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
}
</style>
